@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Material Icons';
  src: url('./assets/google_fonts.woff2');
}

@font-face {
  font-family: 'SBLGRK';
  src: url('./fonts/SBL_grk.ttf');
}

@font-face {
  font-family: 'SBLHBRW';
  src: url('./fonts/SBL_Hbrw.ttf');
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

#root,
body {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

#documentpage {
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: 20px;
  overflow: visible;
  margin-top: 0;
}


#documentloader.notloading {
  display: none;
}

#documentloader.loading {
  display: flex;
}

#documentscontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
  border-radius: 8px;
}

#documentsearchcontainer {
  margin: auto;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-radius: 6px;
  border: 1px solid #ddd;
  transition: border 500ms;
}

#documentsearchcontainer.darkmode {
  border-color: black;
}

#searchdocs {
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
}


#documentscontainer.notloading {
  display: flex;
}

#documentscontainer.loading {
  display: none;
}

#documentloader {
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.ind_doc {
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.ind_doc.small {
  font-size: 12px;
}

.ind_doc.smallmed {
  font-size: 16px;
}

.ind_doc.med {
  font-size: 20px;
}

.ind_doc.medlarge {
  font-size: 24px;
}

.ind_doc.large {
  font-size: 28px;
}

.ind_doc:hover {
  background-color: #A0AEC0;
}

#documentpagetitle {
  margin: 20px 0;
  font-size: 24px;
}

#header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}

#navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link {
  margin: 10px 10px;
  padding: 5px;
  text-decoration: unset;
  transition: border-color 500ms;
}

.nav-link.active {
  border-bottom: 1px solid black;
}

.nav-link.active.darkmode {
  border-bottom: 1px solid white;
}

.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  max-width: 60px;
  border-radius: 50%;
  margin-left: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1111111111;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #eeeeee;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown-content div:hover {
  cursor: pointer;
  background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
  display: block;
}

#site-title {
  font-size: 32px;
}

.App {
  overflow: hidden;
  position: relative;
  height: 100%;
}

#docviewer {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#chosen-doc {
  flex-grow: 1;
  margin-left: 10px;
  border: 1px solid #ddd;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: border 500ms;
}

#chosen-doc.darkmode {
  border-color: black;
}

.ind_doc.selected {
  background-color: #788290;
  color: white;
}


::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

#documentloader.docnotloading {
  display: none !important;
}

#documentloader.docloading {
  display: flex;
}

#documentContent.docloading {
  display: none !important;
}

#doctitle.docloading {
  display: none !important;
}

#documentContent.docnotloading {
  display: block;
}

#doctitle.docnotloading {
  display: flex;
}

#documentContent {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
}

.informational {
  padding: 10px;
  margin: 5px 0;
  font-size: 22px;
  text-align: center;
}

.storyline {
  display: flex;
  margin: 10px 0;
}

.story_original {
  font-size: 22px;
}

.bibsacpopup {
  direction: ltr;
}

.bibsacpopupcontent {
  overflow: scroll;
}

.bibsacpopup.darkmode {
  background-color: #606060;
}

.bibsacarrow.darkmode {
  background-color: #606060;
}

.hebrew {
  direction: rtl;
}

.story_translation {
  font-size: 18px;
  margin-left: 20px;
  font-style: italic;
}

#doctitle {
  text-align: center;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  background-color: #788290;
  padding: 10px 0;
  border-radius: 6px;
  color: white;
}

.informational+.storyline {
  margin-top: 25px;
  border-top: 1px solid black;
  padding-top: 25px;
}

.storyline+.informational {
  margin-top: 25px;
  border-top: 1px solid black;
  padding-top: 25px;
}

.storyword {
  cursor: pointer;
  border-radius: 2px;
  display: inline-block;
  font-family: "Times New Roman", Times, serif;
  font-weight: 400;
}
.storyword.small {
  font-size: 26px;
}
.storyword.smallmed {
  font-size: 30px;
}
.storyword.med {
  font-size: 34px;
}
.storyword.medlarge {
  font-size: 38px;
}
.storyword.large {
  font-size: 42px;
}
.greek .storyword {
  margin-left: 4px;
  font-family: "SBLGRK";
}

.greek .storyword.small {
  margin-left: 4px;
}
.greek .storyword.smallmed {
  margin-left: 4px;
}
.greek .storyword.med {
  margin-left: 5px;
}
.greek .storyword.medlarge {
  margin-left: 5px;
}
.greek .storyword.large {
  margin-left: 6px;
}

.hebrew .storyword {
  font-family: "SBLHBRW";
}

.hebrew .storyword.x-maqqef {
  cursor: unset;
}

.hebrew span.storyword.x-sof-pasuq {
  cursor: unset;
  margin-right: 0;
}

.hebrew span.storyword.x-samekh {
  cursor: unset;
}

.hebrew span.storyword.x-large.small {
  margin-right: 0;
  font-size: 32px;
}
.hebrew span.storyword.x-large.smallmed {
  margin-right: 0;
  font-size: 36px;
}
.hebrew span.storyword.x-large.med {
  margin-right: 0;
  font-size: 40px;
}
.hebrew span.storyword.x-large.medlarge {
  margin-right: 0;
  font-size: 44px;
}
.hebrew span.storyword.x-large.large {
  margin-right: 0;
  font-size: 48px;
}

.storyword.x-paseq {
  cursor: unset;
}

.storyword.x-sof-paseq {
  cursor: unset;
}

.hebrew .storyword.small {
  margin-right: 4px;
}
.hebrew .storyword.small.x-maqqef {
  margin-right: 0;
  margin-left: -4px;
}
.hebrew .storyword.smallmed {
  margin-right: 4px;
}
.hebrew .storyword.smallmed.x-maqqef {
  margin-right: 0;
  margin-left: -4px;
}
.hebrew .storyword.med {
  margin-right: 5px;
}
.hebrew .storyword.med.x-maqqef {
  margin-right: 0;
  margin-left: -5px;
}
.hebrew .storyword.medlarge {
  margin-right: 5px;
}
.hebrew .storyword.medlarge.x-maqqef {
  margin-right: 0;
  margin-left: -5px;
}
.hebrew .storyword.large {
  margin-right: 6px;
}
.hebrew .storyword.large.x-maqqef {
  margin-right: 0;
  margin-left: -6px;
}

.greek .storyword:first-child {
  margin-left: unset;
}

.hebrew .storyword:first-child {
  margin-right: unset;
}

.wordatt {
  text-decoration: underline;
}

.hoveredword {
  text-align: center;
  font-weight: bold;
}

.storyword.open {
  background-color: #788290;
  color: white;
}

.storyword.open.highlighted {
  color: black;
  background-color: #C5C93E;
}

.wordattandval {
  margin: 10px 0;
}

.wordattandval.small {
  font-size: 18px;
}
.wordattandval.smallmed {
  font-size: 22px;
}
.wordattandval.med {
  font-size: 26px;
}
.wordattandval.medlarge {
  font-size: 30px;
}
.wordattandval.large {
  font-size: 34px;
}

#documentslist {
  width: 100%;
}

@media screen and (max-width: 900px) {
  #documentsearchcontainer.docisselected {
    display: none;
  }

  #documentsearchcontainer.docnotselected {
    display: block;
  }

  #chosen-doc.docisselected {
    display: block;
  }

  #chosen-doc.docnotselected {
    display: none;
  }

  #backtodocs {
    display: block;
  }

  #documentscontainer, #documentsearchcontainer, #documentslist {
    width: 100%;
    max-width: unset;
  }
}

@media screen and (min-width: 901px) {
  #documentsearchcontainer.docisselected {
    display: block;
  }

  #chosen-doc.docisselected {
    display: flex;
  }

  #documentsearchcontainer.docnotselected {
    display: block;
  }

  #chosen-doc.docnotselected {
    display: flex;
  }

  #backtodocs {
    display: none;
  }
}

#backtodocs {
  font-size: 32px;
  position: absolute;
  top: 15px;
  cursor: pointer;
  left: 15px;
}

#nav_options_menu {
  font-family: 'Material Icons';
  font-size: 36px;
  margin-left: 15px;
  cursor: pointer;
}

#navigationmenu {
  display: none;
}

@media screen and (max-width: 800px) {
  #navbarfull {
    display: none;
  }

  #navigationmenu {
    display: block;
  }
}


#navmodal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 111111111;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

#navmodal.hide {
  display: none;
}

#navmodal #menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
}

#navmodal #menu.darkmode {
  background-color: #303030;
}


/* home page styles */

#welcomedocumentpage {
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding: 30px;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.welcome {
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

.welcome.small {
  font-size: 28px;
}

.welcome.smallmed {
  font-size: 32px;
}

.welcome.med {
  font-size: 36px;  
}

.welcome.medlarge {
  font-size: 40px;
}

.welcome.large {
  font-size: 44px;
}

#welcome-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

#welcome-block-img {
  width: 100%;
  max-width: 450px;
  height: auto;
  border-radius: 8px;
}

#welcome-block-txt {
  margin: 20px;
  max-width: 100%;
}

.divider {
  margin: 40px 15%;
}

.welcomeabout {
  margin: 0 10%;
}

.welcomeabout p {
  font-size: 18px;
  margin: 10px 0;
}

.designedby {
  text-align: center;
  margin-bottom: 20px;
}

.designedby.small {
  font-size: 8px;
}

.designedby.smallmed{
  font-size: 12px;
}

.designedby.med{
  font-size: 16px;
}

.designedby.medlarge{
  font-size: 20px;
}

.designedby.large{
  font-size: 24px;
}


#bibsac-search-box {
  height: 50px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

#bibsac-search-input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 5px;
  font-size: 18px;
  transition: color 500ms, background-color 500ms;
}

#bibsac-search-input.darkmode {
  background-color: #303030;
  color: white;
}

#bibsac-search-input:focus {
  outline: none;
}

#bibsac-search-icon {
  font-size: 24px;
  margin: 5px;
}


#sm-bibsac-search-box {
  height: 50px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  max-width: 400px;
}

#sm-bibsac-search-input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 5px;
  font-size: 18px;
  transition: color 500ms, background-color 500ms;
}

#sm-bibsac-search-input.darkmode {
  background-color: #303030;
  color: white;
}

#sm-bibsac-search-input:focus {
  outline: none;
}

#bibsac-search-icon {
  font-size: 24px;
  margin: 5px;
}

#sm-bibsac-search-icon {
  font-size: 24px;
  margin: 5px;
}

#fullscreen {
  background-color: white;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111111;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  -moz-box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  transition: color 500ms;
}

#fullscreen.darkmode {
  background-color: #303030;
}

#fullscreen.fullscreen {
  display: block;
}

#closesearch {
  font-size: 32px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

#closesearch:hover {
  color: red;
}

#search-content {
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

#sm-clearsearchinput {
  display: block;
  font-size: 20px;
  cursor: pointer;
}

#sm-clearsearchinput.hidden {
  display: none;
}

#clearsearchinput {
  display: block;
  font-size: 20px;
  cursor: pointer;
}

#clearsearchinput.hidden {
  display: none;
}

#searchfield {
  border: 1px solid black;
  border-radius: 8px;
  height: 40px;
  max-width: 400px;
}

#searchfieldinput {
  border-radius: 8px;
  height: 100%;
  padding: 5px;
  width: 100%;
}

#searchfieldinput:focus {
  outline: none;
}

#searchLoader {
  bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#searchLoader.loading {
  display: flex;
}

.doc_card {
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 6px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.doc_card a {
  pointer-events: none;
}

.doc_card:not(:first-child) {
  margin-top: 20px;
}

.doc_card:hover {
  cursor: pointer;
}

#resultscontainer {
  overflow: scroll;
  margin-top: 20px;
}

.matched_item {
  background-color: yellow;
  color: black;
}

.storyword.highlighted {
  background-color: yellow;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#learningmats {
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: 40px;
  overflow: scroll;
  overflow-x: hidden;
}

#youtubevids {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.learnmat {
  width: 300px;
  min-width: 250px;
  margin: 20px;
  height: 310px;
  cursor: pointer;
  border-radius: 8px;
  padding-bottom: 10px;
  transition: .2s;
}

.learnmat:hover {
  -webkit-box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  -moz-box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.76);
  transform: scale(1.1);
}

.learnmat h3 {
  font-size: 22px;
  text-overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}

.learnmat img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  max-height: 170px;
  width: 100%;
}

.learnmatdesc {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 5px;
}

#createnewstory {
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  text-align: center;
  background-color: #eeeeee;
}

#createnewstory:hover {
  background-color: #A0AEC0;
}

#createnewstory.selected {
  background-color: #788290;
  color: white;
}

.creatorbtn {
  border: 1px solid #A0AEC0;
  padding: 15px;
  border-radius: 8px;
  margin: 10px;
  width: 150px;
}

.creatorbtn:hover {
  background-color: #A0AEC0;
}

#submitcreationcontainer,
#goToConfirmContainer {
  display: flex;
  justify-content: flex-end;
}

#submitcreation,
#goToConfirm {
  border: 1px solid #A0AEC0;
  padding: 15px;
  border-radius: 8px;
  margin: 10px;
  width: 150px;
  color: black;
}



#submitcreation:hover,
#goToConfirm:hover {
  background-color: #A0AEC0;
}

#creatortitlecontainer {
  margin-bottom: 15px;
  max-width: 250px;
  height: 40px;
}

#creatortitle {
  width: 100%;
  height: 100%;
  font-size: 24px;
  padding-left: 3px;
}

#creatortitle:focus {
  outline: none;
}

.createstoryitemrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 1px solid black;
  border-radius: 8px;
  margin-bottom: 15px;
}

.createstorytextarea {
  resize: none;
  padding: 3px;
  border-radius: 8px;
  width: 100%;
}

.createstorytextarea:focus {
  outline: none;
}

.createstorydelete {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
}

.storyitemcontainer {
  flex-grow: 1;
}

#ytvids_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#creatorform {
  margin-top: 20px;
}

#submitcreationcontainer.loading {
  display: none;
}

#submittingLoader {
  display: none;
}

#submittingLoader.loading {
  display: flex;
  justify-content: flex-end;
}

#loadingspinnerbtn {
  border: 1px solid #A0AEC0;
  padding: 15px;
  border-radius: 8px;
  margin: 10px;
  width: 150px;
  color: #A0AEC0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

#creatortitle.fixTitle {
  border: 1px solid red;
  border-radius: 6px;
}

.createstoryitemrow.fixThis {
  border: 1px solid red;
}

.adminEditWordAtts {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

section.bibsacpopup {
  max-width: 400px;
  min-width: 300px;
  min-height: 60px;
}

#editWordForm textarea {
  width: 100%;
  border: 1px solid #A0AEC0;
  resize: none;
  border-radius: 4px;
  padding: 2px;
}

.editAttLabel {
  font-weight: bold;
}

#editWordForm textarea:focus {
  border: 1px solid #788290;
  outline: none;
}

.chakra-modal__header {
  text-align: center;
  padding-bottom: 0;
}

.chakra-button {
  margin: 10px 0;
}

button.chakra-button:hover {
  background-color: #788290ee;
}

button.chakra-button:disabled {
  background-color: #788290;
}

button.chakra-button:hover:disabled {
  background-color: #788290;
}

.editAttLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editAttDelete {
  cursor: pointer;
}

.editAttDelete:hover {
  color: darkred;
}

#editWordBtnsContainer {
  display: flex;
  justify-content: space-between;
}

.editNewAttName {
  border: 1px solid red;
}

.draftLabel {
  margin-left: 5px;
}

button.draftLabel {
  margin-left: 10px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
}

#docnotfound {
  text-align: center;
}

#storyAudio {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#documentpage.audioVisible {
  margin-bottom: 100px;
}

.rfpunct {
  margin-right: -3px;
  margin-left: 3px;
}
.rfpunctfirst {
  margin-right: -3px;
}

.res_title {
  font-size: 24px;
  margin: 10px 0;
}

#tiktokiframe {
  width: 100%;
  min-height: 478px;
  margin-bottom: 40px;
}

.verseNumber {
  display: inline-block;
  font-size: 14px;
}

.greek .verseNumber {
  margin-right: 10px;
}

.hebrew .verseNumber {
  margin-left: 10px;
}

#chapterchooser {
  margin: 0 10px;
  font-size: 14px;
}

#booktitle {
  flex: 1;
}

.link {
  color: #788290;
  border-bottom: 1px solid #788290;
  text-decoration: none;
}

#site-title {
  text-decoration: overline;
}

#bibsac-search-bottom {
  height: 1px;
  border-bottom: 1px solid #ddd;
  margin: 0 10px;
  transition: border 500ms;
}

#bibsac-search-bottom.darkmode {
  border-color: black;
}

#settingsgear {
  font-size: 30px;
  cursor: pointer;
}

.lightdarktoggle {
  --size: 2rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: #e6e6ff;
  transition: all 500ms;
}
.lightdarktoggle:checked {
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);
  transform: scale(0.75);
  color: #ffaa00;
  box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}

#settingscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.settingsitem:first-child {
  margin-top: 0;
}

.settingsitem {
  margin: 10px 0;
}

.horizontal-slider {
  all: initial;
  width: 100%;
  max-width: 500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  all: initial;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #788290;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-track {
  all: initial;
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #A0AEC0;
}
.horizontal-slider .example-track {
  top: 25px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 17px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

#fontsizecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.fontsizeexample {
  width: 80px;
  min-width: 80px;
  margin: 0 10px;
  max-width: 80px;
  text-align: center;
}

.fontsizeexample.small {
  font-size: 16px;
}

.fontsizeexample.large {
  font-size: 36px;
}

.bibsacbtn {
  padding: 10px 15px;
  border: 1px solid #788290;
  border-radius: 6px;
  cursor: pointer;
}

.aboutline.small {
  font-size: 14px;
}

.aboutline.smallmed {
  font-size: 18px;
}

.aboutline.med {
  font-size: 22px;
}

.aboutline.medlarge {
  font-size: 26px;
}

.aboutline.large {
  font-size: 30px;
}

.App {
  transition: all 500ms;
}

.App.darkmode {
  color: white;
  background-color: #303030;
}

.settingsmodalcontent {
  transition: color 500ms, background-color 500ms;
}

.settingsmodalcontent.darkmode {
  color: white;
  background-color: #303030;
}

#mainsettingscontainer {
  width: 100%;
  position: relative;
  padding: 10px;
  overflow: hidden;
}

#chakramodalsettingsbody {
  padding: unset;
}

#settingscontainer {
  width: 100%;
  margin-left: unset;
  transition: left 250ms;
  transform: translateY(50%);
}

#settingscontainer.main {
}

#settingscontainer.login {
  margin-left: calc(-100% - 20px);
}

#logincontainer {
  width: 100%;
  margin-left: calc(100% + 20px);
  transition: margin 250ms;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}
#logincontainer.login {
  margin-left: unset;
}

#logininfo {
  text-align: center;
  font-size: 20px;
}

.bibliacraupdates {
  display: block;
  font-size: 18px;
}

.bibliacraupdates input[type="checkbox"] {
  accent-color: #A0AEC0;
  margin: 0 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

#readingpassagecontainer {
  margin: 10px auto;
  max-width: 800px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

#dailyreader {
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: 20px;
  overflow: visible;
  margin-top: 0;
}

.passageref {
  margin: 20px auto;
  text-align: center;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  background-color: #788290;
  padding: 10px 0;
  border-radius: 6px;
  color: white;
  margin-top: 0;
}

.passagedata {
  padding: 0 20px;
}

.passagedivider {
  margin: 50px 0;
}

#dailyloader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#subscribetoreaddaily, #signupdaily {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

#subscribedailyoptions button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #A0AEC0;
}

#subscribedailyoptions button:hover {
  background-color: #A0AEC0;
}

#subscribedailyoptions button.darkmode {
  border: 1px solid #788290;
}

#subscribedailyoptions button.darkmode:hover {
  background-color: #788290;
}